<template>
    <div>
         <HomeCarousel :items="items"/>
         <SearchForm/>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="post-content  col-md-10" align="center">
              <p class="quote">
                <span
                  > {{$t("pages.about.slogan")}}</span
                >
              </p>
            </div>
          </div>
        <div class="main">
          <div class="container">
            <h1 class="d-inline-block text-center px-3 mb-3 property-offer-type rounded"> {{ $t("pages.home.title_properties") }} </h1>  
          </div>
        </div>
        <div class="">      
        <div class="container">
          <div id="property-listing" class="grid-style1 clearfix col-md-12">
            <Preloader v-if="!properties"/>
            <PropertyCard  
              v-else
              v-for="(item, i) in properties"
              :key="item.folio"
              :name="item.folio"
              :index="i"
              :item="item" 
            />
          </div>	
        </div>	
      </div>     
    </div>
    
    
</template>

<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import HomeCarousel from "@/components/carousel/HomeCarousel.vue";
import Preloader from "@/components/layout/Preloader.vue";
export default {
  components: {
    HomeCarousel,
    SearchForm,
    PropertyCard,
    Preloader
  },
  computed: {
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
      error:'_getError',
    }),
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
  }
};
</script>

<style>
.listing-item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 10px;
}
#propImg {
  width: 100%;
  height: 100%;
}

.navbar{
    height: 100% !important;
    width: 100% !important;

}
</style>
