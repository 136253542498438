<template>
  <div class="item col-sm-4">
    <div class="image">		
      <a @click="goToProperty(item.folio, item.namePropertyEs)" class="btn btn-default">
        <div class="name-prop">
          <h3>{{ item.namePropertyEs }}</h3>
        </div>
      <span class="location" v-if="item.address">
        {{ item.address }}</span>
      </a>	
      <img :src="item.smallphoto" />
    </div>
    <div class="price">
        <i class="fa fa-home"></i>{{item.operation}}
        <span class="iva"><small>*IVA NO INCLUIDO </small></span>
        <span v-if="item.prices.sold!='0'">
          {{ item.prices.sold }} 
        </span>
        <span v-else-if="item.prices.opportunity_rent !='0'">
          {{ item.prices.opportunity_rent  }} 
        </span>
        <span v-else-if="item.prices.rent !='0'">
          {{ item.prices.rent  }} 
        </span>
        <span v-else-if="item.prices.vacation_rent !='0'">
          {{ item.prices.vacation_rent  }}
        </span>
    </div>
      <ul class="amenities">
          <li v-if="item.m2c != 0">
            <i class="icon-area"></i> {{ item.m2c }} m<sup>2</sup>
          </li>
          <li v-else-if="item.mlot != 0">
            <i class="icon-area"></i> {{ item.mlot }} m<sup>2</sup>
          </li>
          <li v-if="item.bedrooms!='0'" >
            <i class="icon-bedrooms"></i> {{ item.bedrooms }}
          </li>
          <li v-if="item.bathrooms!='0'" >
            <i class="icon-bathrooms"></i> {{ item.bathrooms }}
          </li>
      </ul>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainPrice: 0,
      secondPrice: 0,
      mainOperation: 0,
      secondOperation: 0,
    };
  },
  computed: {
    ...mapGetters({
      info:'_getInfoCompany',
    }),
    priceInfo() {
      //Formateo de decimales
      let decimalPrice = this.mainPrice.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = this.mainPrice.indexOf("MXN");
      let currentPriceU = this.mainPrice.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = this.mainPrice.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += this.mainPrice.substr(currentPriceM);
      } else {
        formatPrice += this.mainPrice.substr(currentPriceU);
      }
      return formatPrice;
    },
  },
  
  methods: {
    goToProperty() {
      let namePropFilt = services.normalize(this.item.namePropertyEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Property",
        params: {
          folio,
          namePropFilt,
        },
      });
    },
  },
  created() {
    /* For Base Template*/
    let prices = this.item.prices;
    let operations = this.item.operations;
    if (prices.opportunity_sold) {
      this.mainPrice = prices.opportunity_sold;
      this.mainOperation = operations.opportunity_sold;
      this.secondPrice = prices.sold;
      this.secondOperation = operations.sold;
    } else if (prices.opportunity_rent) {
      this.mainPrice = prices.opportunity_rent;
      this.mainOperation = operations.opportunity_rent;
      this.secondPrice = prices.rent;
      this.secondOperation = operations.rent;
    } else if (prices.sold) {
      this.mainPrice = prices.sold;
      this.mainOperation = operations.sold;
    } else if (prices.rent) {
      this.mainPrice = prices.rent;
      this.mainOperation = operations.rent;
    }
  },
};
</script>

<style scoped>
.iva {
    font-size: 15px !important;
    font-weight: bold !important;
}
 img {
    height: 212px !important;
  }

  .image{
    object-fit: contain;
  }
  @media (max-width: 991px) {
    #contain_tarjet_property{
      width:70%;
    }
  }
   @media (max-width: 520px) {
    #contain_tarjet_property{
      width:100%;
    }
   }

   h3{
     font-size: 15px !important;
   }

  .name-prop{
    white-space: pre-wrap;
  }
</style>